import axios from 'axios'
import React, { useEffect, useState } from 'react'
import logoAE from '../../Assets/Logo/Aditama.png'
import logoBK from '../../Assets/Logo/BK.png'
import logoSN from '../../Assets/Logo/SINET.png'
import { useNavigate } from 'react-router-dom'
import { dateFormated, getRemainingTimeLicense } from '../Utility';
import { Loaders } from '../Utility';

const LicenseDashboard = ({ dataEmployees }) => {

  const [dataDrivingLicense, setDataDrivingLicense] = useState([]);
  const [inputWillExpired, setInputWillExpired] = useState(1);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/license`)
      .then((res) => {
        setDataDrivingLicense(res.data.dataDrivingLicense);
        setLoading(false);
      })
      .catch((err) => console.log('err', err));
  }, []);

  const logos = {
    AE: logoAE,
    BK: logoBK,
    SN: logoSN
  };

  const dataLabel = [
    { logo: 'AE', unit: 'aditama energi' },
    { logo: 'BK', unit: 'bara kumala' },
    { logo: 'SN', unit: 'sinet' }
  ];

  const combinedData = dataEmployees.map(employee => {
    const license = dataDrivingLicense.find(license => license.nik === employee.nik);
    return {
      ...employee,
      ...license
    };
  });

  const expiredLicenses = combinedData.filter(item => {
    const remainingTime = getRemainingTimeLicense(item.date_expired);
    return item.status.toLowerCase() === 'aktif' && remainingTime === 'SIM tidak berlaku';
  });

  const willExpiredLicenses = combinedData.filter(item => {
    const remainingTime = getRemainingTimeLicense(item.date_expired);
    const isOneMonthOrLess = remainingTime === `${inputWillExpired} bulan` ||
      (inputWillExpired === 1 && remainingTime.includes('hari') && parseInt(remainingTime) <= 29);
    return item.status.toLowerCase() === 'aktif' && isOneMonthOrLess;
  });

  return (
    <div className='container'>
      {loading ? (
        <Loaders />
      ) : (
        <div className='container'>
          <div className='d-flex justify-content-between mb-3 align-items-center'>
            <div className='title-h1 fs-1 ms-2'>SIM Polisi</div>
            <div className='title-h1 fs-5'>Sim yg akan kadaluarsa dalam
              <select className='input-none' onChange={(e) => setInputWillExpired(e.target.value)}>
                {Array.from({ length: 11 }, (_, index) => (
                  <option key={index} value={index + 1}>{index + 1}</option>
                ))}
              </select>
              bulan</div>
          </div>
          <div className='d-flex gap-2 justify-content-center align-item-center'>
            {dataLabel.map((item, i) => {
              const filteredExpired = expiredLicenses.filter(emp => emp.unit.toLowerCase() === item.unit);
              const filteredWillExpired = willExpiredLicenses.filter(emp => emp.unit.toLowerCase() === item.unit);

              return (
                <div key={i} className='card p-2 animate__animated animate__fadeInLeft d-flex w-100'>
                  <div className='border-bottom border-2 p-2 text-center w-100'>
                    <img src={logos[item.logo]} alt={`logo ${item.logo}`} className='logo-dashboard' />
                  </div>
                  <div className='title-h1 fs-4 w-75 d-flex mb-3'>Rekap :
                    <i className="fs-5 fw-bold bi bi-chevron-right ms-auto btn btn-sm"
                      onClick={() => navigate(`/dataLicense/${item.logo}`, { state: { dataEmployees, dataDrivingLicense } })}
                    ></i>
                  </div>
                  {filteredWillExpired.length > 0 && (
                    <div className='title-h1'>Daftar SIM yg akan kadaluarsa</div>
                  )}
                  <ul className='ms-3 text-warning'>
                    {willExpiredLicenses.filter(license => license.unit.toLowerCase() === item.unit).map((license, j) => (
                      <li key={j}>{license.fullname} - {dateFormated(license.date_expired)}</li>
                    ))}
                  </ul>
                  {filteredExpired.length > 0 && (
                    <div className='title-h1'>Daftar SIM yg sudah tidak berlaku</div>
                  )}
                  <ul className='ms-3 text-danger'>
                    {expiredLicenses.filter(license => license.unit.toLowerCase() === item.unit).map((license, j) => (
                      <li key={j}>{license.fullname} - {dateFormated(license.date_expired)}</li>
                    ))}
                  </ul>
                  {filteredExpired.length === 0 && filteredWillExpired.length === 0 && (
                    <div className='text-secondary ms-3 title-h1'>* Semua SIM aman 😎</div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default LicenseDashboard;
