import axios from 'axios'
import React, { useEffect, useState } from 'react'
import logoAE from '../../Assets/Logo/Aditama.png'
import logoBK from '../../Assets/Logo/BK.png'
import logoSN from '../../Assets/Logo/SINET.png'
import { useNavigate } from 'react-router-dom'
import { dateFormated, Loaders } from './../Utility/index';

const VacationDashboard = ({ dataEmployees }) => {

  const [dataVacation, setDataVacation] = useState([])
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/vacation`)
      .then((res) => {
        setDataVacation(res.data.dataVacation)
        setLoading(false)
      })
      .catch((err) => console.log(err))
  }, [])

  const logos = {
    AE: logoAE,
    BK: logoBK,
    SN: logoSN
  }

  const dataLebel = [
    { logo: 'AE', unit: 'aditama energi' },
    { logo: 'BK', unit: 'bara kumala' },
    { logo: 'SN', unit: 'sinet' }
  ]

  const currentVacation = dataVacation.filter(vacations => {
    const dateVacation = new Date(vacations.date_vacation) <= new Date()
    const dateWork = new Date(vacations.date_work) >= new Date()
    return dateVacation && dateWork;
  })

  const upComingVacation = dataVacation.filter(vacations => {
    const startDate = new Date(vacations.date_vacation) > new Date()
    return startDate;
  })

  return (
    <div className='container'>
      {loading ? (
        <Loaders />
      ) : (
        <>
          <div className='title-h1 fs-1'>Data Cuti Karyawan</div>
          <div className='d-flex gap-3 container'>
            {dataLebel.map((item, i) => {
              const filterCurrentVacationByUnit = currentVacation.filter(units => units.unit.toLowerCase() === item.unit)
              const filterUpComingVacationByUnit = upComingVacation.filter(units => units.unit.toLowerCase() === item.unit)

              return (
                <div key={i} className='card d-flex flex-column flex-grow-1 align-items-center bg-light shadow shadow-sm animate__animated animate__fadeInRight p-2 w-100'>
                  <div className='border-bottom border-2 p-2 text-center w-100'>
                    <img src={logos[item.logo]} alt={`logo ${item.logo}`} className='logo-dashboard' />
                  </div>
                  <div className='title-h1 fs-4 w-75 d-flex mb-3'>Rekap Cuti :
                    <i className="fs-5 fw-bold bi bi-chevron-right ms-auto btn btn-sm"
                      onClick={() => navigate(`/dataVacation/${item.logo}`, { state: { dataEmployees: dataEmployees, dataVacation: dataVacation } })}
                    ></i>
                  </div>

                  {filterCurrentVacationByUnit.length === 0 && filterUpComingVacationByUnit.length === 0 ? (
                    <div className='w-100 text-center text-muted'>
                      <span className='title-h1 text-secondary'>* Tidak ada yang cuti 😎</span>
                    </div>
                  ) : (
                    <>
                      {filterCurrentVacationByUnit.length > 0 && (
                        <div className='w-100'>
                          <span className='title-h1 fs-5 ms-3'>Masih Cuti :</span>
                          {filterCurrentVacationByUnit.map((items, j) =>
                            <li key={j} className='ms-1'>{`${items.fullname} [${items.category}], Masuk ${dateFormated(items.date_work)}`}</li>
                          )}
                        </div>
                      )}
                      {filterUpComingVacationByUnit.length > 0 && (
                        <div className='w-100'>
                          <span className='title-h1 fs-5 ms-3'>Akan Cuti :</span>
                          {filterUpComingVacationByUnit.map((items, k) =>
                            <li key={k} className='ms-1'>{`${items.fullname} [${items.category}], Cuti ${dateFormated(items.date_vacation)}`}</li>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default VacationDashboard
