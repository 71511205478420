import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import FormRekapPerformance from '../../Performance/Rekap'
import ChartHOK from '../../Performance/Rekap/DahsbordChart/HOK'
import ChartJOK from '../../Performance/Rekap/DahsbordChart/JOK'
import { Carousel } from 'react-bootstrap'
import ChartLate from '../../Performance/Rekap/DahsbordChart/Late'
import ChartAbsent from '../../Performance/Rekap/DahsbordChart/Absent'
import ChartSick from '../../Performance/Rekap/DahsbordChart/Sick'
import ChartPermission from '../../Performance/Rekap/DahsbordChart/Permission'
import ChartGoHome from '../../Performance/Rekap/DahsbordChart/GoHome'
import ChartDR from '../../Performance/Rekap/DahsbordChart/DR'
import ChartKPI from '../../Performance/Rekap/DahsbordChart/KPI'
import ChartEvaluation from '../../Performance/Rekap/DahsbordChart/Evaluation';
import { Loaders } from '../Utility';

const PerformanceDashboard = ({ dataEmployees, isAdmin }) => {

  const [dataPerformance, setDataPerformance] = useState([])
  const [showFormRekap, setShowFormRekap] = useState(false)
  const [loading, setLoading] = useState(true);
  const formRekapRef = useRef(null)
  const navigate = useNavigate()

  const handleClickOutSide = (e) => {
    if (formRekapRef.current && !formRekapRef.current.contains(e.target)) {
      setShowFormRekap(false)
    }
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/performance`)
      .then((res) => {
        setDataPerformance(res.data.dataPerformance)
        setLoading(false)
      })
      .catch((err) => {
        alert('Gagal menampilkan data performance, coba refresh ulang!')
        console.log('Error Performance Dashboard', err)
      })

    document.addEventListener("mousedown", handleClickOutSide)
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide)
    }
  }, [])

  return (
    <>
      {loading ? (
        <Loaders />
      ) : (
        <div className='container'>
          <div className='d-flex justify-content-between'>
            <div className='container title-h1 fs-1'>Kinerja Karyawan</div>
            <div className='title-h1 fs-1 me-3' style={{ cursor: 'pointer' }}
              onClick={() => setShowFormRekap(!showFormRekap)}
              data-tooltip-id='rekap-performance-tooltip' data-tooltip-content="Rekap performance"
            ><i className="bi bi-justify"></i></div>
            <Tooltip id="rekap-performance-tooltip" place='left' />
            {showFormRekap && (
              <FormRekapPerformance formRekapRef={formRekapRef} />
            )}
          </div>
          <div className='detail-employee'>
            {isAdmin && (
              <i className="bi bi-plus-lg fs-1"
                onClick={() => navigate('/inputPerformance', { state: { dataEmployees: dataEmployees } })}
                data-tooltip-id='input-performance-tooltip' data-tooltip-content="input performance"
              ></i>
            )}
            <Tooltip id='input-performance-tooltip' place='left' />
          </div>
          <Carousel className='mt-3' interval={2000} variant='dark'>
            <Carousel.Item>
              <ChartHOK dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartJOK dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartLate dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartAbsent dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartSick dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartPermission dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartGoHome dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartDR dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartKPI dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartEvaluation dataPerformance={dataPerformance} />
            </Carousel.Item>
          </Carousel>
        </div>
      )}
    </>
  )
}

export default PerformanceDashboard
